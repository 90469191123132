import { styled } from 'styled-components';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { Styling } from '@/styling';
import { flexGap } from '@/flexGap';

const Container = styled.div`
  display: inline-flex;
  ${({ theme }) => flexGap(theme.spacing[0.75])};
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Button = styled(Element).attrs({
  as: 'button',
})`
  background-color: ${({ theme }) => theme.palette.grayscale.light4};
  border: 0;
  border-radius: ${Styling.radii('button')};
  cursor: pointer;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.palette.grayscale.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  &.disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.grayscale.light3};
    background-color: ${({ theme }) => theme.palette.grayscale.light4};
  }
`;

export function pagination(
  page: number,
  count: number,
  siblings: number,
  boundary: number,
): (number | '...')[] {
  // TODO use @/utils/number#range
  const range = (start: number, end: number): number[] => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundary, count));
  const endPages = range(Math.max(count - boundary + 1, boundary + 1), count);
  const middleStart = Math.max(
    Math.min(
      // Natural start
      page - siblings,
      // Lower boundary when page is high
      count - boundary - siblings * 2 - 1,
    ),
    // Greater than startPages
    boundary + 2,
  );
  const middleEnd = Math.min(
    Math.max(
      // Natural end
      page + siblings,
      // Upper boundary when page is low
      boundary + siblings * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1,
  );
  const middlePages = range(middleStart, middleEnd);

  return [
    ...startPages,
    ...(middleStart > boundary + 2
      ? ['...' as const]
      : boundary + 1 < count - boundary
        ? [boundary + 1]
        : []),
    ...middlePages,
    ...(middleEnd < count - boundary - 1
      ? ['...' as const]
      : count - boundary > boundary
        ? [count - boundary]
        : []),
    ...endPages,
  ];
}

export type PaginationProps = {
  label?: string;
  disabled?: boolean;
  /**
   * The currently active page
   */
  page: number;
  /**
   * The total number of pages
   */
  count: number;
  /**
   * Determines the number of digits on either side of the currently active page. Defaults to 2.
   *
   * @example
   * where siblings == 2 and boundary == 0:
   * < ... 4 5 6 7 8 ... >
   */
  siblings?: number;
  /**
   * Determines the number of digits on either side of the pagination (the start and end). Defaults to 1.
   *
   * @example
   * where boundary == 2 and siblings == 0
   * < 1 2 ... 5 ... 9 10 >
   */
  boundary?: number;
  onChange?(page: number): void;
};

export const Pagination: FC<PaginationProps> = ({
  label = 'pagination',
  disabled = false,
  page,
  count,
  siblings = 2,
  boundary = 1,
  onChange,
}) => {
  const pages = pagination(page, count, siblings, boundary);
  const nextDisabled = disabled || page >= count;
  const prevDisabled = disabled || page <= 1;

  return (
    <Container aria-label={label}>
      <Button
        aria-label="previous"
        tabIndex={0}
        className={prevDisabled ? 'disabled' : ''}
        onClick={() => prevDisabled || onChange?.(page - 1)}
      >
        <FontAwesomeIcon icon={faAngleLeft} size="xs" />
      </Button>
      {pages.map((elem, index) => {
        if (elem === '...') {
          return <Element key={index}>...</Element>;
        }

        return (
          <Button
            key={index}
            aria-label={`${elem}`}
            tabIndex={0}
            className={page === elem ? 'active' : ''}
            onClick={() => onChange?.(elem)}
          >
            {elem}
          </Button>
        );
      })}
      <Button
        aria-label="next"
        tabIndex={0}
        className={nextDisabled ? 'disabled' : ''}
        onClick={() => nextDisabled || onChange?.(page + 1)}
      >
        <FontAwesomeIcon icon={faAngleRight} size="xs" />
      </Button>
    </Container>
  );
};
