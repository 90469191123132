import { DefaultRootState, InboxAuthState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EndUser, InboxLoginResponse } from '@idk-web/api';

const initialState: InboxAuthState = {
  status: 'LOGGED_OUT',
  token: null,
  user: null,
};

export const inboxAuthSlice = createSlice({
  name: 'inbox/auth',
  initialState: initialState as InboxAuthState,
  reducers: {
    logout(): InboxAuthState {
      return initialState;
    },
    login(
      state,
      action: PayloadAction<
        Extract<InboxLoginResponse, { status: 'COMPLETED' }>
      >,
    ): InboxAuthState {
      const payload = action.payload;

      return {
        status: 'LOGGED_IN',
        token: payload.token,
        user: {
          displayName: payload.user.displayName,
          email: payload.user.email,
          hasValidatedEmail: payload.user.hasValidatedEmail,
        },
      };
    },
    updateUser(state, action: PayloadAction<EndUser>): void {
      if (state.user) {
        state.user.email = action.payload.email;
        state.user.displayName = action.payload.displayName;
        state.user.hasValidatedEmail = action.payload.hasValidatedEmail;
      }
    },
  },
});

export const { logout, login, updateUser } = inboxAuthSlice.actions;

export const selectInboxLoginStatus = (state: DefaultRootState) =>
  state.inboxAuth.status;
export const selectInboxUser = (state: DefaultRootState) =>
  state.inboxAuth.user;

export default inboxAuthSlice.reducer;
