import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { setTag, setUser, withProfiler } from '@sentry/react';
import app from '@/utils/app';
import { selectInboxUser } from '@/redux/inboxAuth.slice';

const SentryProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { i18n } = useTranslation();
  const inboxUser = useSelector(selectInboxUser);

  useEffect(() => {
    setTag('idk.lang', i18n.language);
  }, [i18n]);

  useEffect(() => {
    setTag('idk.env', app.env);
  }, []);

  useEffect(() => {
    if (inboxUser) {
      setUser({ username: 'InboxUser()' });
    } else {
      setUser(null);
    }
  }, [inboxUser]);

  return <>{children}</>;
};

export default withProfiler(SentryProvider);
