import { styled } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
  useBreakPoint,
  hostname,
  Styling,
  ResponsiveImage,
  Divider,
  Box,
  useDialog,
  MobileProfileMenu,
  ProfileMenu,
} from '@idk-web/core-ui';
import {
  selectInboxLoginStatus,
  selectInboxUser,
} from '@/redux/inboxAuth.slice';
import { useInboxLogin } from '@/hooks/inbox/login/useInboxLogin';
import routes from '@/components/inbox/routes';
import LanguageSelect from '@/components/common/input/LanguageSelect';
import LogoutDialog from '@/components/common/dialog/LogoutDialog';
import idkollen from '@/assets/svg/idkollen.svg';
import identisure from '@/assets/svg/identisure.svg';

const Container = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  min-height: 80px;
  z-index: ${Styling.zIndex('header')};
  box-shadow: ${Styling.shadow('header')};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  padding: 0 ${Styling.spacing(8)};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: 0 ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: 0 ${Styling.spacing(2)};
  }
`;

const Section = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  alignY: 'center',
  spacing: theme.spacing[2],
}))``;

const Logo = styled(ResponsiveImage).attrs({
  alt: 'Logo', // TODO translate
})`
  max-width: 160px;
  max-height: 120px;
  padding: ${Styling.spacing(2)} 0;
`;

const ShortDivider = styled(Divider)`
  height: 16px;
`;

const Header: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const history = useHistory();
  const breakpoint = useBreakPoint('xl');
  const auth = useInboxLogin();
  const user = useSelector(selectInboxUser);
  const loginStatus = useSelector(selectInboxLoginStatus);
  const isLoggedIn = loginStatus === 'LOGGED_IN';

  const handleGoToProfile = () => history.push(routes.profile);

  const handleLogout = () => {
    const handleOk = () => auth.logout();

    dialog.show((props) => <LogoutDialog {...props} onOk={handleOk} />);
  };

  const menuItems = [
    {
      content: t('inbox.header.menu.profile.item'),
      onClick: handleGoToProfile,
    },
    'divider' as const,
    {
      content: t('inbox.header.menu.log_out.item'),
      onClick: handleLogout,
    },
  ];

  return (
    <Container>
      <Link to={routes.inbox}>
        <Logo src={hostname.tld === 'se' ? idkollen : identisure} />
      </Link>
      <Section>
        <LanguageSelect />
        {isLoggedIn && (
          <>
            <ShortDivider orientation="vertical" />
            {breakpoint.below ? (
              <MobileProfileMenu items={menuItems} />
            ) : (
              <ProfileMenu
                username={user?.displayName ?? user?.email ?? 'Anonymous'}
                items={menuItems}
              />
            )}
          </>
        )}
      </Section>
    </Container>
  );
};

export default Header;
