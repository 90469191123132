import { DependencyList, useCallback, useState } from 'react';

export type ForceRerender = () => void;

/**
 * A hacky hook that can be used to force re-render a component.
 */
export function useForceRerender(deps: DependencyList = []): ForceRerender {
  const [_count, setCount] = useState(0);

  return useCallback(() => setCount((count) => count + 1), deps);
}
