import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { SentryRoute } from '@/sentry';
import { selectInboxLoginStatus } from '@/redux/inboxAuth.slice';
import routes from '@/components/inbox/routes';

export type UnauthenticatedRouteProps = RouteProps;

const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectInboxLoginStatus);

  if (loginStatus === 'LOGGED_IN') {
    return <Redirect to={routes.inbox} />;
  }

  return <SentryRoute {...props} />;
};

export default UnauthenticatedRoute;
