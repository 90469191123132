import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { SentryRoute } from '@/sentry';
import { selectInboxLoginStatus } from '@/redux/inboxAuth.slice';
import routes from '@/components/inbox/routes';

export type AuthenticatedRouteProps = RouteProps;

const AuthenticatedRoute: FC<AuthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectInboxLoginStatus);

  if (loginStatus === 'LOGGED_OUT') {
    return <Redirect to={routes.login.replace(':country?', '')} />;
  }

  return <SentryRoute {...props} />;
};

export default AuthenticatedRoute;
