import { wait } from '@idk-web/core-utils';

/**
 * Triggers a download of the given blob
 */
export function downloadBlob(blob: Blob, filename: string): void {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // `link.click()` does not work in all browsers when the link is not part of the DOM, but dispatch works
  link.dispatchEvent(
    new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }),
  );

  // Some browsers return an error if we revoke the url too quickly, so we wait a short while
  void wait(200).then(() => URL.revokeObjectURL(url));
}
