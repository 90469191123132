import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import {
  SecureButton,
  Platform,
  platform,
  Styling,
  StandardCard,
  Typography,
  Box,
  DialogProps,
  SpinningCircleLoader,
  TertiaryButton,
} from '@idk-web/core-ui';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  max-width: 500px;
`;

const TextSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))`
  margin-bottom: ${Styling.spacing(2)};
`;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[2],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Header = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Text = styled(Typography).attrs<{ type?: string }>({
  type: 'body',
})`
  color: #888888; // TODO move to theme
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))`
  ${Styling.typography('body')};
  color: #888888; // TODO move to theme
`;

const QrContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function treatAsSafari(): boolean {
  const ua = navigator.userAgent;
  return !ua.match('EdgiOS') && !ua.match('FxiOS') && !ua.match('CriOS');
}

type BankIdQrDialogDialogProps = {
  getData(): {
    autoStartToken: string;
    qrData: string;
  } | null;
  onCancel?(): void;
} & DialogProps;

const BankIdQrDialog: FC<BankIdQrDialogDialogProps> = ({
  getData,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<{
    autoStartToken: string;
    qrData: string;
  } | null>(null);

  useEffect(() => {
    const id = setInterval(async () => {
      setData(getData());
    }, 1000);

    return () => clearInterval(id);
  }, [getData]);

  const openOnSameDevice = () => {
    if (platform === Platform.IOS && treatAsSafari()) {
      window.location.href = `https://app.bankid.com/?autostarttoken=${data!.autoStartToken}&redirect=null`;
    } else {
      window.location.href = `bankid:///?autostarttoken=${data!.autoStartToken}&redirect=null`;
    }
  };

  const handleCancel = () => {
    onCancel?.();
    onClose?.();
  };

  return (
    <DialogCard>
      <TextSection>
        <Header>{t('login.bankid_qr_dialog.title')}</Header>
        <Text>{t('login.bankid_qr_dialog.text')}</Text>
      </TextSection>
      <Content>
        <QrContainer>
          {data ? (
            <QRCodeCanvas value={data.qrData} size={180} />
          ) : (
            <SpinningCircleLoader />
          )}
        </QrContainer>
        {data && (
          <>
            <Typography type="body">{t('common.or')}</Typography>
            <SecureButton
              text={t('login.bankid.open_this_device')}
              onClick={openOnSameDevice}
            />
          </>
        )}
      </Content>
      <ButtonSection>
        <TertiaryButton
          text={t('login.bankid_qr_dialog.cancel')}
          onClick={handleCancel}
        />
      </ButtonSection>
    </DialogCard>
  );
};

export default BankIdQrDialog;
