import { styled } from 'styled-components';
import signatureGreen from '@/assets/svg/signature_green.svg';
import signatureGray from '@/assets/svg/signature_gray.svg';

type SignatureIconProps = {
  signed: boolean;
};

export const SignatureIcon = styled.img.attrs<SignatureIconProps>(
  ({ signed }) => ({
    src: signed ? signatureGreen : signatureGray,
    alt: '',
  }),
)<SignatureIconProps>`
  height: 36px;
  width: 36px;
`;
