import { AxiosResponse } from 'axios';
import {
  AdminPermission,
  Country,
  Language,
  PortalTab,
  ServiceType,
} from '@/types';
import { client } from '@/client';

export type PortalUserLoginRequest = {
  username: string;
  password: string;
};

export type CodeLoginRequest = {
  id: string;
  code: string;
};

export type AuthenticateRequest = {
  ref: string; // 'token' for changing company, 'refId' otherwise...
  companyId: number;
  force?: boolean;
};

export type CountryConfigurationResponse = {
  teleidEnabled: boolean;
  teleidSsnEnabled: boolean;
  teleidSsnNorwayEnabled: boolean;
  teleidEmailEnabled: boolean;
  teleidSmsEnabled: boolean;
  teleidChatEnabled: boolean;
  teleidMaxUsersNumber: number;
  teleidCounter: number;
  telesignEnabled: boolean;
  telesignSsnEnabled: boolean;
  telesignEmailEnabled: boolean;
  telesignSmsEnabled: boolean;
  telesignChatEnabled: boolean;
  telesignCounter: number;
  telesignMaxUsersNumber: number;
  safemailEnabled: boolean;
  safemailCounter: number;
  safemailMaxUsersNumber: number;
  country: Country;
};

export type PortalUserCompanyResponse = {
  companyCode: string;
  companyId: number;
  isAdmin: boolean;
  logAccess: boolean;
  companyName: string;
  services: ServiceType[] | null;
  countries: CountryConfigurationResponse[];
};

export type AdminLoginResponse =
  | {
      status: 'IN_PROGRESS';
      ref: string;
      url?: string;
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      status: 'COMPLETED';
      token: string;
      user: {
        ssn: string;
        country: Country;
        name: string;
        role?: string;
        permissions: AdminPermission[];
        effectivePermissions: AdminPermission[];
      };
    }
  | {
      status: 'FAILED';
      ref: string;
      error: string;
    };

export type PortalLoginResponse =
  | {
      status: 'IN_PROGRESS';
      ref: string;
      url?: string;
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      status: 'COMPLETED';
      ref: string;
      mustChangePassword: boolean;
      hasValidatedEmail: boolean;
      user: {
        id: number;
        username: string;
        displayName: string;
        country: Country | null;
        email: string | null;
      };
      companies: PortalUserCompanyResponse[];
      sso?: {
        registrationId: string;
      };
    }
  | {
      status: 'FAILED';
      ref: string;
      error: string;
    };

export type InboxLoginResponse =
  | {
      status: 'IN_PROGRESS';
      ref: string;
      url?: string;
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      status: 'COMPLETED';
      token: string;
      user: {
        displayName: string;
        email: string | null;
        hasValidatedEmail: boolean;
      };
    }
  | {
      status: 'FAILED';
      ref: string;
      error: string;
    };

export type FormIdLoginResponse =
  | {
      status: 'IN_PROGRESS';
      ref: string;
      url?: string;
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      status: 'COMPLETED';
      token: string;
    }
  | {
      status: 'FAILED';
      ref: string;
      error: string;
    };

export type AuthenticationResponse = {
  token: string;
  user: {
    isAdmin: boolean;
    canSeeLogs: boolean;
    availableServices: {
      type: ServiceType;
      countries: Country[];
    }[];
  };
  company: {
    id: number;
    name: string;
    logoUrl: string;
    formIdOnlySafemail: boolean;
    defaultStartTab: PortalTab;
    telesignDefaultText: string | null;
    telesignDefaultTextDisabled: boolean;
    requestSignatureEnabled: boolean;
    defaultCountry: Country;
  };
};

export async function initAdminNorwayLogin(
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/admin/norway/login-url', { signal })
    .then((response) => response.data);
}

export async function initAdminFinlandLogin(
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/admin/finland/login-url', { signal })
    .then((response) => response.data);
}

export async function initAdminDenmarkLogin(
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/admin/denmark/login-url', { signal })
    .then((response) => response.data);
}

export async function initPortalNorwayLogin(
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/norway/portal-login-url', { signal })
    .then((response) => response.data);
}

export async function initPortalFinlandLogin(
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/finland/portal-login-url', { signal })
    .then((response) => response.data);
}

export async function initPortalDenmarkLogin(
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/denmark/portal-login-url', { signal })
    .then((response) => response.data);
}

export async function initInboxNorwayLogin(
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/norway/inbox-login-url', { signal })
    .then((response) => response.data);
}

export async function initInboxFinlandLogin(
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/finland/inbox-login-url', { signal })
    .then((response) => response.data);
}

export async function initInboxDenmarkLogin(
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/denmark/inbox-login-url', { signal })
    .then((response) => response.data);
}

export async function initFormIdNorwayLogin(
  formId: number,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/norway/form-login-url', { params: { formId }, signal })
    .then((response) => response.data);
}

export async function initFormIdFinlandLogin(
  formId: number,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/finland/form-login-url', { params: { formId }, signal })
    .then((response) => response.data);
}

export async function initFormIdDenmarkLogin(
  formId: number,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .get('/auth/denmark/form-login-url', { params: { formId }, signal })
    .then((response) => response.data);
}

export async function swedenAdminLogin(
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .post('/auth/admin/login', null, { signal })
    .then((response) => response.data);
}

export async function norwayAdminLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/admin/norway/login', request, { signal })
    .then((response) => response.data);
}

export async function finlandAdminLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/admin/finland/login', request, { signal })
    .then((response) => response.data);
}

export async function denmarkAdminLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<AdminLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/admin/denmark/login', request, { signal })
    .then((response) => response.data);
}

export async function getAdminLoginResult(
  ref: string,
  signal?: AbortSignal,
): Promise<AdminLoginResponse> {
  return client
    .get(`/auth/admin/result/${encodeURIComponent(ref)}`, { signal })
    .then((response) => response.data);
}

export async function swedenPortalLogin(
  request?: PortalUserLoginRequest,
  signal?: AbortSignal,
): Promise<PortalLoginResponse> {
  return client
    .post('/auth/login', request ?? {}, { signal })
    .then((response) => response.data);
}

export async function norwayPortalLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/norway/login', request, { signal })
    .then((response) => response.data);
}

export async function finlandPortalLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/finland/login', request, { signal })
    .then((response) => response.data);
}

export async function denmarkPortalLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/denmark/login', request, { signal })
    .then((response) => response.data);
}

export async function ssoPortalLogin(
  code: string,
  signal?: AbortSignal,
): Promise<Extract<PortalLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/sso/login', { id: code, code }, { signal })
    .then((response) => response.data);
}

export async function getPortalLoginResult(
  ref: string,
  signal?: AbortSignal,
): Promise<PortalLoginResponse> {
  return client
    .get(`/auth/result/${encodeURIComponent(ref)}`, { signal })
    .then((response) => response.data);
}

export async function authenticatePortal(
  request: AuthenticateRequest,
  signal?: AbortSignal,
): Promise<AuthenticationResponse> {
  return client
    .post<
      AuthenticateRequest,
      AxiosResponse<AuthenticationResponse>
    >('/auth/authenticate', request, { signal })
    .then((response) => response.data);
}

export async function changeCompany(
  request: AuthenticateRequest,
  signal?: AbortSignal,
): Promise<AuthenticationResponse> {
  return client
    .post<
      AuthenticateRequest,
      AxiosResponse<AuthenticationResponse>
    >('/auth/authenticate/company', request, { signal })
    .then((response) => response.data);
}

export async function swedenInboxLogin(
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .post(`/auth/pno/login`, null, { signal })
    .then((response) => response.data);
}

export async function norwayInboxLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/norway/enduser/login', request, { signal })
    .then((response) => response.data);
}

export async function finlandInboxLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/finland/enduser/login', request, { signal })
    .then((response) => response.data);
}

export async function denmarkInboxLogin(
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<InboxLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/denmark/enduser/login', request, { signal })
    .then((response) => response.data);
}

export async function getInboxLoginResult(
  ref: string,
  signal?: AbortSignal,
): Promise<InboxLoginResponse> {
  return client
    .get(`/auth/inbox/result/${encodeURIComponent(ref)}`, { signal })
    .then((response) => response.data);
}

export async function swedenFormIdLogin(
  formId: number,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .post(`/auth/sweden/gdprform/login`, null, { params: { formId }, signal })
    .then((response) => response.data);
}

export async function norwayFormIdLogin(
  formId: number,
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/norway/gdprform/login', request, {
      params: { formId },
      signal,
    })
    .then((response) => response.data);
}

export async function finlandFormIdLogin(
  formId: number,
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/finland/gdprform/login', request, {
      params: { formId },
      signal,
    })
    .then((response) => response.data);
}

export async function denmarkFormIdLogin(
  formId: number,
  request: CodeLoginRequest,
  signal?: AbortSignal,
): Promise<Extract<FormIdLoginResponse, { status: 'COMPLETED' }>> {
  return client
    .post('/auth/denmark/gdprform/login', request, {
      params: { formId },
      signal,
    })
    .then((response) => response.data);
}

export async function getFormIdLoginResult(
  ref: string,
  signal?: AbortSignal,
): Promise<FormIdLoginResponse> {
  return client
    .get(`/auth/sweden/gdprform/result/${encodeURIComponent(ref)}`, { signal })
    .then((response) => response.data);
}

export async function logoutAdmin(signal?: AbortSignal): Promise<void> {
  return client
    .post('/auth/admin/logout', null, { signal })
    .then((response) => response.data);
}

export async function logoutPortal(signal?: AbortSignal): Promise<void> {
  return client
    .post('/auth/logout', null, { signal })
    .then((response) => response.data);
}

export async function resetPassword(
  username: string,
  language: Language,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .post(
      `/auth/reset-password`,
      {
        username,
        language,
      },
      { signal },
    )
    .then((response) => response.data);
}

export async function changePassword(
  code: string,
  password: string,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .post(
      '/auth/change-password',
      {
        code,
        password,
      },
      { signal },
    )
    .then((response) => response.data);
}
