/**
 * A sorting function to be used in `Array::sort`.
 */
export type SortFn<T> = (a: T, b: T) => number;

export enum Ordering {
  ASCENDING,
  DESCENDING,
}

export enum NullOrder {
  FIRST,
  LAST,
}

export type Fields<T> = {
  /**
   * The key to sort.
   */
  name: keyof T;
  /**
   * The sorting order for the given key. Defaults to `DESCENDING`.
   */
  order?: Ordering;
};

export type SortOptions = {
  /**
   * Determines whether `null` and `undefined` values are put at the start or at the end of the result.
   */
  nulls?: NullOrder;
};

/**
 * Returns a sorting function which can sort an array based on multiple fields.
 *
 * @example
 *  [{ a: 1, b: 1 }, { a: null, b: 0 }, { a: 1, b: 2 }]
 *    .sort(sortBy([
 *      { name: 'a', order: Ordering.DESCENDING },
 *      { name: 'b', order: Ordering.ASCENDING },
 *    ], {
 *      nulls: NullOrder.LAST
 *    }));
 */
export function sortBy<T>(
  fields: Fields<T>[],
  options?: SortOptions,
): SortFn<T> {
  const nulls = options?.nulls ?? NullOrder.LAST;

  return (a, b) => {
    for (const field of fields) {
      const order = field.order ?? Ordering.DESCENDING;
      const aValue = a[field.name];
      const bValue = b[field.name];

      if (aValue === undefined || aValue === null) {
        return nulls === NullOrder.FIRST ? -1 : 1;
      }

      if (aValue > bValue) {
        return order === Ordering.ASCENDING ? 1 : -1;
      }

      if (aValue < bValue) {
        return order === Ordering.ASCENDING ? -1 : 1;
      }
    }

    return 0;
  };
}
