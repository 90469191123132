import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Sentry } from '@idk-web/core-utils';
import { useIsMounted } from '@idk-web/core-ui';
import { InboxLoginResponse } from '@idk-web/api';
import { isBankIDError, translateError } from '@/utils/error';
import {
  login as reduxLogin,
  logout as reduxLogout,
} from '@/redux/inboxAuth.slice';
import * as inboxLogin from '@/hooks/inbox/login/inboxLogin';
import { StartedInboxLoginRequest } from '@/hooks/inbox/login/inboxLogin';

type State =
  | {
      state: 'IDLE';
    }
  | {
      state: 'CANCELLED';
    }
  | {
      state: 'TIMED_OUT';
    }
  | {
      state: 'WAITING';
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      state: 'COMPLETE';
    }
  | {
      state: 'FAILED';
      error: string;
    };

export type InboxLogin = {
  loginSweden(): Promise<void>;
  loginNorway(window: Window): Promise<void>;
  loginDenmark(window: Window): Promise<void>;
  loginFinland(window: Window): Promise<void>;
  logout(): Promise<void>;
  cancel(): Promise<void>;
  reset(): void;
} & State;

export function useInboxLogin(): InboxLogin {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const request = useRef<StartedInboxLoginRequest>();
  const [state, setState] = useState<State>({ state: 'IDLE' });
  const onInit = useCallback(
    async (data: Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>) => {
      onProgress(data);
    },
    [],
  );
  const onComplete = useCallback(
    async (result: Extract<InboxLoginResponse, { status: 'COMPLETED' }>) => {
      dispatch(reduxLogin(result));

      if (isMounted()) {
        setState({ state: 'COMPLETE' });
      }

      request.current = undefined;
    },
    [],
  );
  const onError = useCallback(
    async (error: unknown) => {
      if (isMounted()) {
        if (error === 'cancelled') {
          setState({ state: 'CANCELLED' });
        } else if (error === 'timed out') {
          setState({ state: 'TIMED_OUT' });
        } else {
          if (!isBankIDError(error)) {
            Sentry.reportError('Inbox login failed', { error });
          }

          setState({ state: 'FAILED', error: translateError(t, error) });
        }
      }

      request.current = undefined;
    },
    [t],
  );
  const onProgress = useCallback(
    async (data: Extract<InboxLoginResponse, { status: 'IN_PROGRESS' }>) => {
      if (isMounted()) {
        if (data.bankId) {
          setState({ state: 'WAITING', bankId: data.bankId });
        } else {
          setState({ state: 'WAITING' });
        }
      }
    },
    [],
  );
  const loginSweden = useCallback(async (): Promise<void> => {
    setState({ state: 'WAITING' });

    request.current = inboxLogin
      .sweden()
      .onInit(onInit)
      .onComplete(onComplete)
      .onError(onError)
      .onProgress(onProgress)
      .start();

    return request.current.wait();
  }, [onInit, onComplete, onError, onProgress]);
  const loginNorway = useCallback(
    async (window: Window): Promise<void> => {
      setState({ state: 'WAITING' });

      request.current = inboxLogin
        .norway(window)
        .onInit(onInit)
        .onComplete(onComplete)
        .onError(onError)
        .onProgress(onProgress)
        .start();

      return request.current.wait();
    },
    [onInit, onComplete, onError, onProgress],
  );
  const loginDenmark = useCallback(
    async (window: Window): Promise<void> => {
      setState({ state: 'WAITING' });

      request.current = inboxLogin
        .denmark(window)
        .onInit(onInit)
        .onComplete(onComplete)
        .onError(onError)
        .onProgress(onProgress)
        .start();

      return request.current.wait();
    },
    [onInit, onComplete, onError, onProgress],
  );
  const loginFinland = useCallback(
    async (window: Window): Promise<void> => {
      setState({ state: 'WAITING' });

      request.current = inboxLogin
        .finland(window)
        .onInit(onInit)
        .onComplete(onComplete)
        .onError(onError)
        .onProgress(onProgress)
        .start();

      return request.current.wait();
    },
    [onInit, onComplete, onError, onProgress],
  );
  const logout = useCallback(async (): Promise<void> => {
    dispatch(reduxLogout());
    reset();
  }, []);
  const cancel = useCallback(async () => {
    if (isMounted()) {
      setState({ state: 'CANCELLED' });
    }

    return request.current?.cancel();
  }, []);
  const reset = useCallback(() => {
    if (isMounted()) {
      setState({ state: 'IDLE' });
    }

    request.current = undefined;
  }, []);

  return useMemo(
    () => ({
      loginSweden,
      loginNorway,
      loginDenmark,
      loginFinland,
      logout,
      cancel,
      reset,
      ...state,
    }),
    [
      loginSweden,
      loginNorway,
      loginDenmark,
      loginFinland,
      logout,
      cancel,
      reset,
      state,
    ],
  );
}
