import { TFunction } from 'i18next';
import { Axios } from '@idk-web/core-utils';

/**
 * Tries to extract an error message from the given input.
 */
function extractErrorMessage(input: unknown): string | null {
  if (input === undefined || input === null) {
    return null;
  }

  if (typeof input === 'string') {
    return input;
  }

  if (input instanceof Error) {
    if (Axios.isError(input)) {
      return Axios.extractErrorMessage(input);
    }

    return input.message;
  }

  return null;
}

function translateLoginError(t: TFunction, error: string): string | null {
  if (error.includes('Too many failed login attempts')) {
    return t('login.error.max_attempts');
  }

  if (
    error.includes('Invalid credentials') ||
    error.includes('User not found')
  ) {
    return t('login.error.invalid_credentials');
  }

  if (error.includes('Account suspended')) {
    return t('login.error.account_suspended');
  }

  if (
    error.includes('Wrong authentication ref') ||
    error.includes('Invalid or expired ref')
  ) {
    return t('login.error.expired_session');
  }

  return null;
}

function translatePrivateAppError(t: TFunction, error: string): string | null {
  if (error.includes('Too many auth requests in progress at the same time')) {
    return t('errors.too_many_auth_requests');
  }

  return null;
}

const BankIdErrors = [
  'alreadyInProgress',
  'startFailed',
  'userCancel',
  'cancelled',
  'expiredTransaction',
  'certificateErr',
  'userDeclinedCall',
  'notSupportedByUserApp',
] as const;

export type BankIdError = (typeof BankIdErrors)[number];

export function translateBankIDError(t: TFunction, error: BankIdError): string {
  switch (error) {
    case 'alreadyInProgress':
      return t('login.error.already_in_progress');
    case 'startFailed':
      return t('login.error.start_failed');
    case 'cancelled':
    case 'userCancel':
    case 'userDeclinedCall':
    case 'notSupportedByUserApp':
      return t('login.error.cancelled');
    case 'expiredTransaction':
      return t('login.error.expired_transaction');
    case 'certificateErr':
      return t('login.error.expired_certificate');
  }
}

export function isBankIDError(error: unknown): error is BankIdError {
  const message = extractErrorMessage(error);

  if (!message) {
    return false;
  }

  return (BankIdErrors as readonly string[]).includes(message);
}

export function translateError(t: TFunction, error: unknown): string {
  const message = extractErrorMessage(error);

  if (!message) {
    return t('login.error.unexpected', { error });
  }

  if (isBankIDError(message)) {
    return translateBankIDError(t, message);
  }

  if (message === 'Network Error ERR_NETWORK') {
    return t('login.error.network');
  }

  return (
    translateLoginError(t, message) ??
    translatePrivateAppError(t, message) ??
    t('login.error.unexpected', {
      error: message,
      interpolation: { escapeValue: false },
    })
  );
}
