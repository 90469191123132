import { combineReducers } from 'redux';
import locale from '@/redux/locale.slice';
import inboxFilter from '@/redux/inboxFilter.slice';
import inboxAuth from '@/redux/inboxAuth.slice';

export default combineReducers({
  locale,
  inboxAuth,
  inboxFilter,
});
