import { useSelector, InboxUser, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { getOwnEndUser } from '@idk-web/api';
import { selectInboxUser, updateUser } from '@/redux/inboxAuth.slice';

export type User = InboxUser & {
  /**
   * Reloads the current user globally
   */
  reload(): Promise<void>;
};

/**
 * Returns the currently logged in inbox user.
 *
 * This hook may only be used in components that are only mounted when an inbox
 * user is guaranteed to be logged in.
 */
export function useInboxUser(): User {
  const dispatch = useDispatch();
  const inboxUser = useSelector(selectInboxUser);
  const reload = useCallback(async () => {
    if (!inboxUser) {
      console.warn('Tried to reload user when user is missing in state');
      return;
    }

    const user = await getOwnEndUser();
    dispatch(updateUser(user));
  }, [inboxUser]);

  return useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...inboxUser!,
      reload,
    }),
    [inboxUser, reload],
  );
}
