import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export type QueryParams<T extends Record<string, string>> = {
  /**
   * Returns the query parameter value of the given key, if any.
   */
  get(key: keyof T): string | null;
  /**
   * Sets the value of the given query parameter key. Passing a value of `null` will remove
   * the query parameter.
   *
   * Note that this will replace the current React Router history entry, unless `silent` is
   * set to `false` in which case a new entry is pushed onto the React Router history stack.
   * State is always persisted.
   */
  set(key: keyof T, value: string | null, silent?: boolean): void;
};

/**
 * A hook used for reading and updating query parameters.
 *
 * This hook will react to changes to the React Router history.
 */
export function useQueryParams<
  T extends Record<string, string> = {},
>(): QueryParams<T> {
  const history = useHistory();
  const location = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    const entries = Object.fromEntries(params) as T;

    return {
      get(key) {
        return entries[key] ?? null;
      },
      set(key, value, silent = true) {
        if (value === null) {
          params.delete(key as string);
        } else {
          params.set(key as string, value);
        }

        const descriptor = {
          pathname: location.pathname,
          search: '?' + params,
        };

        if (silent) {
          history.replace(descriptor, location.state);
        } else {
          history.push(descriptor, location.state);
        }
      },
    };
  }, [location.search]);
}
