import { DefaultRootState, InboxFilterState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InboxFilterState = {
  messagesPerPage: 10,
};

export const inboxFilterSlice = createSlice({
  name: 'inbox/filter',
  initialState: initialState as InboxFilterState,
  reducers: {
    setMessagesPerPage(state, action: PayloadAction<number>): void {
      state.messagesPerPage = action.payload;
    },
  },
});

export const { setMessagesPerPage } = inboxFilterSlice.actions;

export const selectMessagesPerPage = (state: DefaultRootState) =>
  state.inboxFilter.messagesPerPage;

export default inboxFilterSlice.reducer;
