import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Styling,
  StandardCard,
  Overlay,
  Box,
  SpinningCircleLoader,
} from '@idk-web/core-ui';

const Container = styled(StandardCard)`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  border-collapse: collapse;
  overflow: auto;
  scrollbar-color: ${({ theme }) => theme.palette.primary.main} white;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

const Header = styled.thead`
  ${Styling.typography('body')};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.grayscale.white};
`;

const Body = styled.tbody`
  ${Styling.typography('body')};
`;

const Row = styled.tr<{ selected: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ selected }) => (selected ? '#EBF6FD' : 'inherit')};

  &:hover {
    background-color: ${({ selected }) => (selected ? '#EBF6FD' : '#FBFBFB')};
  }
`;

type CellProps = {
  align?: 'left' | 'center' | 'right';
};

const HeaderCell = styled.th<CellProps>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${Styling.spacing(3, 1.5)};
  position: sticky;
  top: 0;
  text-align: ${({ align }) => align ?? 'left'};
  white-space: nowrap;
`;

const DataCell = styled.td`
  padding: ${Styling.spacing(3, 1.5)};
  border-bottom: 1px solid #edf2f7; // TODO move to theme
`;

const EmptyContent = styled(Box).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  padding: ${Styling.spacing(6, 3)};
`;

type LoadingOverlayProps = {
  show: boolean;
  offset: number;
};

const LoadingOverlay: FC<LoadingOverlayProps> = ({ show, offset }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: `${offset}px`,
        right: `-${offset}px`,
        pointerEvents: 'none',
      }}
    >
      <Overlay show={show} color="rgba(255, 255, 255, 0.5)">
        <Box
          alignX="center"
          alignY="center"
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        >
          <SpinningCircleLoader />
        </Box>
      </Overlay>
    </div>
  );
};

export type TableColumn<T> = {
  key: string;
  header: ReactNode;
  accessor(row: T): ReactNode;
  headerAlignment?: 'left' | 'center' | 'right';
  headerWidth?: string;
};

export type InboxDataTableProps<T extends object> = {
  loading?: boolean;
  selection?: T;
  onSelect?(row: T | null): void;
  columns: TableColumn<T>[];
  rows: T[];
  emptyText?: ReactNode;
};

const InboxDataTable = <T extends object>({
  loading = false,
  selection,
  onSelect,
  columns,
  rows,
  emptyText,
}: InboxDataTableProps<T>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        setScrollLeft(ref.current?.scrollLeft ?? 0);
      }
    };

    ref.current?.addEventListener('scroll', handleScroll);

    return () => ref.current?.removeEventListener('scroll', handleScroll);
  }, []);

  if (emptyText === undefined) {
    emptyText = t('input.no_data');
  }

  return (
    <Container ref={ref}>
      <LoadingOverlay show={loading} offset={scrollLeft} />
      <Table>
        <Header>
          <tr>
            {columns.map((column) => (
              <HeaderCell
                key={column.key}
                align={column.headerAlignment}
                style={{ width: column.headerWidth }}
              >
                {column.header}
              </HeaderCell>
            ))}
          </tr>
        </Header>
        <Body>
          {rows.map((row, rowIndex) => (
            <Row
              key={String(rowIndex)}
              selected={selection === row}
              onClick={() => onSelect?.(row)}
            >
              {columns.map((column) => (
                <DataCell key={column.key}>{column.accessor(row)}</DataCell>
              ))}
            </Row>
          ))}
        </Body>
      </Table>
      {rows.length === 0 && (
        <EmptyContent>{!loading && emptyText}</EmptyContent>
      )}
    </Container>
  );
};

export default InboxDataTable;
