import { styled } from 'styled-components';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Ordering, sortBy, Sentry } from '@idk-web/core-utils';
import {
  downloadBlob,
  useQueryParams,
  Styling,
  FilledSelect,
  AlternativeActionIconButton,
  DangerActionIconButton,
  Bold,
  Sensitive,
  Pagination,
  Tooltip,
  Box,
  useDialog,
  useNotification,
  ConfirmDialog,
  ErrorDialog,
} from '@idk-web/core-ui';
import {
  deleteInboxMessage,
  getInboxAttachment,
  InboxMessage,
} from '@idk-web/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons/faFileArrowDown';
import { translateError } from '@/utils/error';
import {
  selectMessagesPerPage,
  setMessagesPerPage,
} from '@/redux/inboxFilter.slice';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import { InboxMessageWithSigners } from '@/components/inbox/safemail/SafeMailInbox';
import InboxDataTable from '@/components/inbox/safemail/InboxDataTable';

const RESULT_PER_PAGE_OPTIONS = [10, 25, 50];

const DataContainer = styled.div`
  width: 100%;
  white-space: nowrap;
`;

const MessageActions = styled(Box).attrs(({ theme }) => ({
  spacing: theme.spacing[1.5],
  alignX: 'right',
}))``;

const MessageActionTooltip = styled(Tooltip)`
  padding: 6px 12px;
  background: ${({ theme }) => theme.palette.grayscale.white};
  color: black;
  border-radius: 4px;
`;

const Bar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: ${Styling.spacing(2)};
  @media (max-width: ${Styling.breakpoint('md')}px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const BarLeft = styled.div`
  display: flex;
  justify-content: start;
`;

const BarMiddle = styled.div`
  display: flex;
  justify-content: center;
`;

const BarRight = styled.div`
  display: flex;
  justify-content: end;
`;

type QueryParams = {
  page: string;
};

export type InboxCardProps = {
  loading?: boolean;
  messages: InboxMessageWithSigners[];
  selection?: InboxMessageWithSigners;
  onSelect?(message: InboxMessageWithSigners | null): void;
  onDelete?(message: InboxMessageWithSigners): void;
};

const InboxCard: FC<InboxCardProps> = ({
  loading,
  messages,
  selection,
  onSelect,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialog = useDialog();
  const notification = useNotification();
  const params = useQueryParams<QueryParams>();
  const token = useSelector((state: DefaultRootState) => state.inboxAuth.token);
  const [page, setPage] = useState(1);
  const messageCountOptions = useMemo(
    () =>
      RESULT_PER_PAGE_OPTIONS.map((count) => ({
        value: String(count),
        label: t('inbox.safemail.messages_per_page', { count }),
      })),
    [t],
  );
  const resultsPerPage = useSelector(selectMessagesPerPage);
  const sortedMessages = useMemo(
    () =>
      messages.sort(
        sortBy([
          { name: 'datetime', order: Ordering.DESCENDING },
          { name: 'id', order: Ordering.ASCENDING },
        ]),
      ),
    [messages],
  );
  const pagedMessages = useMemo(() => {
    const from = (page - 1) * resultsPerPage;
    const to = from + resultsPerPage;

    return sortedMessages.slice(from, to) ?? [];
  }, [sortedMessages, page, resultsPerPage]);

  useEffect(() => {
    const page = params.get('page');

    if (page) {
      const number = Number(page);

      if (!isNaN(number) && number > 0) {
        setPage(number);
      }
    }
  }, []);

  const handlePageChange = (page: number): void => {
    setPage(page);
    params.set('page', String(page));
  };

  const handlePageCountChange = (count: number): void => {
    dispatch(setMessagesPerPage(count));
    handlePageChange(1);
  };

  const handleSelect = (selection?: InboxMessageWithSigners | null): void => {
    if (selection) {
      onSelect?.(selection);

      setTimeout(() => {
        // Using timeout to prevent scroll from being interrupted by layout shift in Firefox
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 24);
    } else {
      onSelect?.(null);
    }
  };

  const handleDelete = (message: InboxMessage) => {
    const handleOk = async () => {
      try {
        await deleteInboxMessage(message.id);
        onDelete?.(message);

        notification.show(
          t('inbox.safemail.delete.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );
      } catch (e) {
        Sentry.reportError('Failed to delete end user inbox message', {
          error: e,
        });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }
    };

    dialog.show((props) => (
      <ConfirmDialog
        {...props}
        header={t('common.are_you_sure')}
        text={t('inbox.safemail.delete.are_you_sure')}
        onOk={handleOk}
      />
    ));
  };

  const handleDownloadAttachment = async (
    message: InboxMessageWithSigners,
    filename: string,
  ) => {
    try {
      const blob = await getInboxAttachment(message.id, token);
      downloadBlob(blob, filename);
    } catch (e) {
      Sentry.reportError('Failed to download SafeMail inbox attachment', {
        error: e,
      });
      dialog.show((props) => (
        <ErrorDialog {...props} text={translateError(t, e)} />
      ));
    }
  };

  const getMessageActions = (message: InboxMessageWithSigners): ReactNode => {
    return (
      <MessageActions>
        <MessageActionTooltip content={t('common.delete')}>
          <DangerActionIconButton
            icon={<FontAwesomeIcon icon={faTrashCan} size="lg" />}
            onClick={(evt: React.MouseEvent) => {
              evt.stopPropagation(); // Prevent row click
              handleDelete(message);
            }}
          />
        </MessageActionTooltip>
      </MessageActions>
    );
  };

  const getAttachmentActions = (
    message: InboxMessageWithSigners,
  ): ReactNode => {
    let fileName: string | null;

    if (message.zipCreated) {
      fileName = 'signature.zip';
    } else if (message.attachmentName) {
      fileName = message.attachmentName;
    } else {
      fileName = null;
    }

    return (
      <MessageActions>
        {fileName && (
          <MessageActionTooltip content={fileName}>
            <AlternativeActionIconButton
              icon={<FontAwesomeIcon icon={faFileArrowDown} size="lg" />}
              onClick={() => handleDownloadAttachment(message, fileName!)}
            />
          </MessageActionTooltip>
        )}
      </MessageActions>
    );
  };

  const pageCount = Math.ceil((messages.length || 1) / resultsPerPage);

  return (
    <>
      <DataContainer>
        <InboxDataTable<InboxMessageWithSigners>
          emptyText={t('inbox.safemail.data.empty')}
          loading={loading}
          selection={selection ?? undefined}
          onSelect={handleSelect}
          columns={[
            {
              key: 'datetime',
              header: t('inbox.safemail.data.date'),
              headerAlignment: 'left',
              accessor: (message) => {
                const date = new Date(
                  message.datetime * 1000,
                ).toLocaleDateString([], {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                });

                return message.wasRead ? date : <Bold>{date}</Bold>;
              },
            },
            {
              key: 'companyName',
              header: t('inbox.safemail.data.sender'),
              headerAlignment: 'left',
              accessor: (message) =>
                message.wasRead ? (
                  message.companyName
                ) : (
                  <Bold>{message.companyName}</Bold>
                ),
            },
            {
              key: 'subject',
              header: t('inbox.safemail.data.subject'),
              headerAlignment: 'left',
              accessor: (message) => (
                <Sensitive>
                  {message.wasRead ? (
                    message.subject
                  ) : (
                    <Bold>{message.subject}</Bold>
                  )}
                </Sensitive>
              ),
            },
            {
              key: 'attachments',
              header: t('inbox.safemail.data.attachments'),
              headerAlignment: 'right',
              headerWidth: '100%',
              accessor: getAttachmentActions,
            },
            {
              key: 'actions',
              header: t('inbox.safemail.data.actions'),
              headerAlignment: 'right',
              accessor: getMessageActions,
            },
          ]}
          rows={pagedMessages}
        />
      </DataContainer>
      <Bar>
        <BarLeft>
          <FilledSelect
            disabled={loading}
            value={String(resultsPerPage)}
            options={messageCountOptions}
            onChange={(value) => handlePageCountChange(Number(value))}
          />
        </BarLeft>
        <BarMiddle>
          <Pagination
            page={page}
            onChange={handlePageChange}
            count={pageCount}
          />
        </BarMiddle>
        <BarRight />
      </Bar>
    </>
  );
};

export default InboxCard;
