import * as Yup from 'yup';
import { styled } from 'styled-components';
import { DefaultRootState, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Duration, Sentry } from '@idk-web/core-utils';
import {
  downloadBlob,
  hostname,
  loseFocus,
  useBreakPoint,
  useIsMounted,
  Styling,
  StandardCard,
  Bold,
  Sensitive,
  SignatureIcon,
  ResponsiveImage,
  Box,
  useNotification,
  useDialog,
  DialogHandle,
  AlternativeButton,
  DangerButton,
  DownloadButton,
  SecureButton,
  ConfirmDialog,
  ErrorDialog,
} from '@idk-web/core-ui';
import {
  downloadInboxTeleSignPdf,
  deleteInboxMessage,
  forwardInboxMessage,
  getInboxAttachment,
} from '@idk-web/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons/faFileArrowDown';
import { translateError } from '@/utils/error';
import { useInboxUser } from '@/hooks/inbox/useInboxUser';
import { useInboxSign } from '@/hooks/inbox/sign/useInboxSign';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import { InboxMessageWithSigners } from '@/components/inbox/safemail/SafeMailInbox';
import BankIdQrDialog from '@/components/common/dialog/bankid/BankIdQrDialog';

const Card = styled(StandardCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${Styling.spacing(6)};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(6, 4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(4, 2)};
  }
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[4],
  alignX: 'center',
}))``;

const MessageSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const MessageSubject = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-align: center;
`;

const MessageBody = styled.div`
  ${Styling.typography('body')};
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: #ebf6fd;
  word-break: break-word;
  border-radius: 4px;
  padding: ${Styling.spacing(3)};

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SignSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const SignersHeader = styled.h3`
  ${Styling.typography('h3')};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-align: center;
`;

const Signer = styled(Box).attrs({
  direction: 'horizontal',
  alignX: 'center',
  alignY: 'center',
})``;

const DeletionMessage = styled.div`
  ${Styling.typography('body')};
  text-align: right;
  color: ${({ theme }) => theme.palette.grayscale.dark1};
`;

const BottomBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  gap: ${Styling.spacing(4)};

  @media (max-width: ${Styling.breakpoint('md')}px) {
    grid-template-columns: auto;
  }
`;

const AttachmentSection = styled(Box).attrs({
  direction: 'vertical',
})``;

const DeleteSection = styled(Box).attrs(({ theme }) => ({
  spacing: theme.spacing[1],
  direction: 'vertical',
}))``;

const Hash = styled.div`
  margin-top: 8px;
  word-break: break-all;
`;

const ButtonGroup = styled(Box).attrs(({ theme }) => ({
  spacing: theme.spacing[2],
}))`
  width: auto;
`;

const CompanyLogo = styled(ResponsiveImage).attrs({
  alt: '',
})`
  max-width: 160px;
  max-height: 120px;
`;

export type InboxMessageCardProps = {
  message: InboxMessageWithSigners;
  onSign?(): void;
  onForward?(): void;
  onDelete?(message: InboxMessageWithSigners): void;
};

const InboxMessageCard: FC<InboxMessageCardProps> = ({
  message,
  onSign,
  onForward,
  onDelete,
}) => {
  const breakpoint = useBreakPoint('md');
  const { t } = useTranslation();
  const dialog = useDialog();
  const notification = useNotification();
  const isMounted = useIsMounted();
  const user = useInboxUser();
  const token = useSelector((state: DefaultRootState) => state.inboxAuth.token);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isForwarding, setIsForwarding] = useState(false);
  const inboxSign = useInboxSign();
  const inboxSignRef = useRef(inboxSign);
  const qrDialogRef = useRef<DialogHandle>();
  const signFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({}),
    onSubmit() {
      loseFocus();
      void inboxSign.signSweden(message.id);
    },
  });
  const deletedInDays = useMemo(() => {
    const deletionDate = Date.parse(message.autodeleteDate);
    const duration = Duration.betweenTimestamps(
      Date.now() / 1000,
      deletionDate / 1000,
      ['days'],
    );

    return duration.days;
  }, [message]);

  useEffect(() => {
    inboxSignRef.current = inboxSign;
  }, [inboxSign]);

  useEffect(() => {
    if (inboxSign.state === 'FAILED') {
      dialog.show((props) => <ErrorDialog {...props} text={inboxSign.error} />);
    }

    if (inboxSign.state === 'TIMED_OUT') {
      dialog.show((props) => (
        <ErrorDialog
          {...props}
          header={t('error.timed_out.title')}
          text={t('error.timed_out.text')}
        />
      ));
    }

    if (inboxSign.state === 'COMPLETE') {
      notification.show(
        t('inbox.safemail.sign.success'),
        'success',
        DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
      );

      onSign?.();
    }

    if (
      inboxSign.state === 'FAILED' ||
      inboxSign.state === 'CANCELLED' ||
      inboxSign.state === 'TIMED_OUT'
    ) {
      inboxSign.reset();
    }
  }, [inboxSign.state]);

  useEffect(() => {
    if (inboxSign.state !== 'WAITING') {
      closeQrDialog();
    }

    return () => closeQrDialog();
  }, [inboxSign.state]);

  useEffect(() => {
    if (signFormik.isSubmitting && inboxSign.state === 'WAITING') {
      const handleCancel = () => {
        closeQrDialog();
        void inboxSign.cancel();
      };

      qrDialogRef.current = dialog.show(
        (props) => (
          <BankIdQrDialog
            {...props}
            getData={() => {
              if (
                inboxSignRef.current.state === 'WAITING' &&
                inboxSignRef.current.bankId
              ) {
                return inboxSignRef.current.bankId;
              }

              return null;
            }}
            onCancel={handleCancel}
          />
        ),
        { closeOnBackgroundClick: false, closeOnEscape: false },
      );

      return () => handleCancel();
    }
  }, [signFormik.isSubmitting, inboxSign.state]);

  const closeQrDialog = () => {
    qrDialogRef.current?.close();
    qrDialogRef.current = undefined;
  };

  const handleDownloadAttachment = async () => {
    setIsDownloading(true);

    try {
      const attachment = await getInboxAttachment(message.id, token);
      let fileName: string;

      if (message.zipCreated) {
        fileName = 'signature.zip';
      } else if (message.attachmentName) {
        fileName = message.attachmentName;
      } else {
        fileName = 'attachment';
      }

      downloadBlob(attachment, fileName);
    } catch (e) {
      Sentry.reportError('Failed to download end user inbox attachment', {
        error: e,
      });
      dialog.show((props) => (
        <ErrorDialog {...props} text={translateError(t, e)} />
      ));
    }

    if (isMounted()) {
      setIsDownloading(false);
    }
  };

  const handleSign = () => {
    signFormik.handleSubmit();
  };

  const handleForward = async () => {
    const handleOk = async () => {
      setIsForwarding(true);

      try {
        await forwardInboxMessage(message.id);

        notification.show(
          t('inbox.safemail.forward.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );

        onForward?.();
      } catch (e) {
        Sentry.reportError('Failed to forward end user inbox message', {
          error: e,
        });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }

      if (isMounted()) {
        setIsForwarding(false);
      }
    };

    dialog.show((props) => (
      <ConfirmDialog
        {...props}
        header={t('common.are_you_sure')}
        text={t('inbox.safemail.forward.are_you_sure')}
        onOk={handleOk}
      />
    ));
  };

  const handleDelete = () => {
    const handleOk = async () => {
      try {
        await deleteInboxMessage(message.id);
        onDelete?.(message);

        notification.show(
          t('inbox.safemail.delete.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );
      } catch (e) {
        Sentry.reportError('Failed to delete end user inbox message', {
          error: e,
        });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }
    };

    dialog.show((props) => (
      <ConfirmDialog
        {...props}
        header={t('common.are_you_sure')}
        text={t('inbox.safemail.delete.are_you_sure')}
        onOk={handleOk}
      />
    ));
  };

  const getSubject = (): string => {
    if (message.requestSignature) {
      if (message.zipCreated) {
        return t('inbox.safemail.sign.document_signed_by_all');
      } else {
        return t('inbox.safemail.sign.signature_request');
      }
    }

    return message.subject;
  };

  const handleDownloadPdf = async () => {
    setIsDownloading(true);

    try {
      const download = await downloadInboxTeleSignPdf(message.id);
      downloadBlob(download, `${user.displayName.replace(/\s+/g, '_')}.pdf`);
    } catch (e) {
      Sentry.reportError('Failed to download TeleSign PDF', { error: e });
      dialog.show((props) => (
        <ErrorDialog {...props} text={translateError(t, e)} />
      ));
    }

    if (isMounted()) {
      setIsDownloading(false);
    }
  };

  const canSign =
    !message.messageSigned &&
    Boolean(message.requestSignature) !== Boolean(message.zipCreated) &&
    hostname.tld === 'se';
  const canForward =
    Boolean(message.requestSignature) === Boolean(message.zipCreated);

  return (
    <Card>
      <Content>
        {message.companyLogoUrl && (
          <div>
            <CompanyLogo src={message.companyLogoUrl} />
          </div>
        )}
        <MessageSection>
          <MessageSubject>
            <Sensitive>{getSubject()}</Sensitive>
          </MessageSubject>
          {message.zipCreated ? (
            <div>
              {t('inbox.safemail.sign.signed_document_message')}
              &nbsp;
              {t('inbox.safemail.sign.signed_document_recommendation')}
              &nbsp;
              <Bold>
                {t('inbox.safemail.sign.signed_document_autodelete', {
                  days: deletedInDays,
                })}
              </Bold>
            </div>
          ) : (
            <MessageBody>
              <Sensitive>
                <div dangerouslySetInnerHTML={{ __html: message.body ?? '' }} />
                <div
                  dangerouslySetInnerHTML={{ __html: message.footer ?? '' }}
                />
              </Sensitive>
            </MessageBody>
          )}
        </MessageSection>
        {message.signers && (
          <SignSection>
            <SignSection>
              <SignersHeader>
                {t('inbox.safemail.sign.document_signers')}
              </SignersHeader>
              {message.signers.map((signer, index) => {
                return (
                  <Signer key={`${signer.pno}${index}`}>
                    <SignatureIcon signed={Boolean(signer.signatureDate)} />
                    {signer.signatureDate ? (
                      <Sensitive>
                        <p>
                          <Bold>{signer.displayName}</Bold>
                          {` - ${t('inbox.safemail.sign.signed')} ${new Date(
                            signer.signatureDate * 1000,
                          ).toLocaleString()}`}
                        </p>
                      </Sensitive>
                    ) : (
                      <Sensitive>
                        <p>{`${signer.email} ${signer.pno}`}</p>
                      </Sensitive>
                    )}
                  </Signer>
                );
              })}
            </SignSection>
            {canSign && (
              <Box direction="vertical" alignX="center" spacing="8px">
                <SecureButton
                  disabled={
                    inboxSign.state === 'WAITING' ||
                    !signFormik.isValid ||
                    message.messageSigned
                  }
                  loading={
                    inboxSign.state === 'WAITING' && signFormik.isSubmitting
                  }
                  text={
                    message.messageSigned
                      ? t('inbox.safemail.sign.document_signed_by_user')
                      : t('inbox.safemail.sign.button')
                  }
                  onClick={handleSign}
                />
              </Box>
            )}
          </SignSection>
        )}
        <BottomBar>
          <AttachmentSection alignX={breakpoint.above ? 'left' : 'center'}>
            {message.attachmentName && (
              <>
                <DownloadButton
                  disabled={isDownloading}
                  text={
                    <>
                      {message.zipCreated
                        ? t('inbox.safemail.attachment.download_signature')
                        : t('inbox.safemail.attachment.download_attachment')}
                    </>
                  }
                  startAdornment={
                    <FontAwesomeIcon icon={faFileArrowDown} size="lg" />
                  }
                  onClick={handleDownloadAttachment}
                />
                {message.attachmentHash && (
                  <Hash>SHA256 hash: {message.attachmentHash}</Hash>
                )}
              </>
            )}
            {/* note: if there isn't a subject then it's a TeleSign message */}
            {!message.subject && (
              <>
                <DownloadButton
                  disabled={isDownloading}
                  text={t('inbox.safemail.download_pdf.button')}
                  startAdornment={
                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                  }
                  onClick={handleDownloadPdf}
                />
              </>
            )}
          </AttachmentSection>
          <DeleteSection alignX={breakpoint.above ? 'right' : 'center'}>
            <ButtonGroup>
              {canForward && (
                <AlternativeButton
                  disabled={isForwarding}
                  text={t('inbox.safemail.forward.button')}
                  onClick={handleForward}
                />
              )}
              <DangerButton text={t('common.delete')} onClick={handleDelete} />
            </ButtonGroup>
            {message.autodeleteDate && (
              <DeletionMessage>
                {t('inbox.safemail.message_will_autodelete')}{' '}
                {new Date(message.autodeleteDate).toLocaleDateString()}.
              </DeletionMessage>
            )}
          </DeleteSection>
        </BottomBar>
      </Content>
    </Card>
  );
};

export default InboxMessageCard;
