import { styled } from 'styled-components';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, Suspense, useCallback, useEffect } from 'react';
import { Axios } from '@idk-web/core-utils';
import {
  useVisibility,
  useSuspended,
  Box,
  ErrorBoundary,
} from '@idk-web/core-ui';
import { SentryRoute } from '@/sentry';
import { selectInboxLoginStatus } from '@/redux/inboxAuth.slice';
import { useInboxUser } from '@/hooks/inbox/useInboxUser';
import { useInboxLogin } from '@/hooks/inbox/login/useInboxLogin';
import SafeMailInbox from '@/components/inbox/safemail/SafeMailInbox';
import routes from '@/components/inbox/routes';
import UnauthenticatedRoute from '@/components/inbox/UnauthenticatedRoute';
import Layout from '@/components/inbox/Layout';
import AuthenticatedRoute from '@/components/inbox/AuthenticatedRoute';
import ScheduledMaintenanceMessage from '@/components/common/ScheduledMaintenanceMessage';
import BrowserSupportMessage from '@/components/common/BrowserSupportMessage';

const ErrorScreen = React.lazy(() => import('@/components/error/ErrorScreen'));
const LoginCallback = React.lazy(
  () => import('@/components/common/auth/LoginCallback'),
);
const LoginScreen = React.lazy(
  () => import('@/components/inbox/login/LoginScreen'),
);
const EmailValidationScreen = React.lazy(
  () => import('@/components/inbox/email/EmailValidationScreen'),
);
const EmailRequestScreen = React.lazy(
  () => import('@/components/inbox/email/EmailRequestScreen'),
);
const ProfileScreen = React.lazy(
  () => import('@/components/inbox/profile/ProfileScreen'),
);
const NotFoundScreen = React.lazy(
  () => import('@/components/notFound/NotFoundScreen'),
);

const Container = styled(Box).attrs({
  direction: 'vertical',
})``;

const InboxApp: FC = () => {
  const auth = useInboxLogin();
  const user = useInboxUser();
  const loginStatus = useSelector(selectInboxLoginStatus);
  const visibility = useVisibility();
  const reloadUser = useCallback(async () => {
    if (loginStatus !== 'LOGGED_IN' || visibility !== 'visible') {
      return;
    }

    try {
      await user.reload();
    } catch (e) {
      if (Axios.isError(e) && e.response && Axios.is4xxResponse(e.response)) {
        await auth.logout();
      } else {
        console.error('Failed to reload inbox user', e);
      }
    }
  }, [loginStatus, visibility]);

  useSuspended(reloadUser, [reloadUser]);

  useEffect(() => {
    void reloadUser();
  }, [reloadUser]);

  return (
    <Container>
      <ErrorBoundary fallback={ErrorScreen}>
        <BrowserSupportMessage />
        <ScheduledMaintenanceMessage />
        <Layout>
          <Suspense fallback={null}>
            <Switch>
              <SentryRoute path={routes.loginCallback}>
                <LoginCallback
                  redirect={routes.login.replace(':country?', '')}
                />
              </SentryRoute>
              <SentryRoute
                path={routes.validateEmail}
                component={EmailValidationScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.login}
                component={LoginScreen}
              />
              <AuthenticatedRoute
                exact
                path={routes.inbox}
                render={() => {
                  if (loginStatus === 'LOGGED_IN' && !user?.email) {
                    return <EmailRequestScreen />;
                  }

                  return <SafeMailInbox />;
                }}
              />
              <AuthenticatedRoute
                exact
                path={routes.profile}
                component={ProfileScreen}
              />
              <SentryRoute component={NotFoundScreen} />
            </Switch>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Container>
  );
};

export default InboxApp;
