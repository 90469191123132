import { AxiosResponse } from 'axios';
import { client } from '@/client';

export type EndUser = {
  pno: string;
  email: string;
  displayName: string;
  hasValidatedEmail: boolean;
};

export type UpdateEmailRequest = {
  email: string;
};

export async function getOwnEndUser(signal?: AbortSignal): Promise<EndUser> {
  return client
    .get<EndUser>('/enduser/me', { signal })
    .then((response) => response.data);
}

export async function updateOwnEndUserEmail(
  email: string,
  signal?: AbortSignal,
): Promise<EndUser> {
  return client
    .put<
      UpdateEmailRequest,
      AxiosResponse<EndUser>
    >('/enduser', { email }, { signal })
    .then((response) => response.data);
}

export async function deleteOwnEndUser(signal?: AbortSignal): Promise<void> {
  return client
    .delete('/enduser/me', { signal })
    .then((response) => response.data);
}

export async function validateEndUserEmail(
  code: string,
  signal?: AbortSignal,
): Promise<void> {
  return client.post('/enduser/email/validate', null, {
    params: { code },
    signal,
  });
}
